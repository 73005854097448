//<link rel="stylesheet" media="screen" href="assets/vendor/swiper/swiper-bundle.min.css">
//<link rel="stylesheet" media="screen" href="assets/vendor/aos/dist/aos.css">
//<link rel="stylesheet" media="screen" href="assets/vendor/simplebar/dist/simplebar.min.css">
//<!-- Main Theme Styles + Bootstrap-->
//<link rel="stylesheet" media="screen" href="assets/css/theme.min.css">

$icons-font-path: "../fonts/around";
$lg-path-images: "../img/lightgallery";
$lg-path-fonts: "../fonts/lightgallery";

$carousel-bullet-size: 0.75rem;
$carousel-bullet-spacer: 0.5rem;

$primary: rgb(63,	149, 148);
$secondary: #DC4160;

@import "~swiper/swiper-bundle";
@import "~aos/dist/aos";
@import "around/theme";
@import "~lightgallery/scss/lightgallery-bundle";

.form-submitted, .form-error {
  .form-submitted-hidden {
    @extend .d-none;
  }
}

.form-submitted {
  .bg-form {
    @extend .bg-success;
  }
  .valid-feedback {
    display: initial;
    @extend .text-light;
  }
}

.form-error {
  .bg-form {
    @extend .bg-danger;
  }
  .invalid-feedback {
    display: initial;
    @extend .text-light;
  }
}

.swiper-slide-duplicate, .swiper-slide-next, .swiper-slide-prev  {
   .swiper-hide-inactive {
     display: none;
   }
}
